import React from "react";

// 组件
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import Video from "../../../new-components/Video";
import IconItem from "../../../new-components/IconItem";
import ContactBottom from '../../../new-components/ContactBottom';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle';

import './index.less';

// 配置
import config from './config';
import "../../../global.js";

function SessionC(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: currItemConfig.backgroundImage,
          borderRadius: 'unset',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="session-c"> {inner}</div>
}

const SessionCWithTitle = RenderWithTitle(SessionC, "平台特点", 5, 2);

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "平台架构图", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionEWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionE.imgSrc} alt="" /></div>, "核心中台能力", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

export default () => {
  return (
    <div className="qianchao-page">
      <Head />

      <Abstract {...config.sessionA} />

      <Video {...config.sessionB} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle {...config.sessionD} />

      <SessionEWithTitle {...config.sessionE} />

      <ContactBottom />

      <Bottom />
    </div>
  )
};