import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/19/`;
export default {
  sessionA: {
    imgSrc: `img/product_goujian_banner_pic01.png`,
    describe: "建筑建材预制构件（预制混凝土构件）作为一种标准化、批量化建材生产材料，是现代建筑工业化的重要组成部分，促进了 建筑行业的转型升级，同时预制构件企业的信息化也随着企业的发展提出了新的要求，新中大建筑建材构件生产管理系统应运而 生，为预制构件业务提供全生命周期管控。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML: '建筑建材构件生产企业数字化解决方案',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    imgSrc: `img/project_pic04.jpg`,
  },
  sessionC: {
    imgSrc: `img/project_pic05.jpg`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        // title: '互联互通，共享共治',
        describe: '部门协同与数据共享'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        // title: '制度流程化，流程信息化',
        describe: '合格证一键生成'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        // title: '随时随地，移动办公',
        describe: '构件信息数据一体化溯源平台'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        // title: '知识管理，创造价值',
        describe: '一体化合同结算闭环管理'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        // title: '随时随地，移动办公',
        describe: '自动排产'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        // title: '知识管理，创造价值',
        describe: 'BIM实时查看台座进度'
      }
    ]
  },
  sessionE: {
    title: '中交第三航务工程局有限公司',
    describe: `中交第三航务工程局有限公司，成立于1954年，是世界500强企业中交集团的全资子公司，经过70年来发展，已经成为一家全土木、多元化的大型国有骨干建筑企业。立足上海，放眼全球。服务上海国际航运中心建设，参建东海大桥、洋山深水港、花博会项目等一系列特大型工程，业务范围覆盖全国30多个省级行政单位和全球约30个国家和地区，是“一带一路”建设的主力军。`,
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}