import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/qianchao/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/new-product/qianchao/1.png`,
    describe: "新中大钱潮技术平台全面引入云原生和中台的设计理念，打造全面的中台支撑体系，涵盖技术中台、数据中台、业务中台、移动中台、AI中台等能力，秉承一体化的建设理念构建强大的数字化底座，赋能企业快速完成数字化转型。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "钱潮技术平台",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },

  sessionB: {
    src: global.config.url + '/NewGrand/media/qianchao.mp4'
  },

  // 平台特点
  sessionC: {
    colCount: 3,
    rowCount: 2,
    items: [
      {
        key: 0,
        backgroundImage: `url(${baseUrl}5.png)`,
        describe: '云原生微服务架构'
      },
      {
        key: 1,
        backgroundImage: `url(${baseUrl}2.png)`,
        describe: '千人千面自由选配'
      },
      {
        key: 2,
        backgroundImage: `url(${baseUrl}4.png)`,
        describe: 'IMP互动管理平台，单据交互随心所欲'
      },
      {
        key: 3,
        backgroundImage: `url(${baseUrl}3.png)`,
        describe: 'web化业务建模、业务个性扩展'
      },
      {
        key: 4,
        backgroundImage: `url(${baseUrl}6.png)`,
        describe: '全新工作流引擎'
      }
    ]
  },

  // 平台架构图
  sessionD: {
    imgSrc: `${baseUrl}7.png`,
  },

  // 核心中台能力
  sessionE: {
    imgSrc: `${baseUrl}8.png`,
  }
}