/*
 * @Author: your name
 * @Date: 2019-12-30 11:25:04
 * @LastEditTime: 2023-05-29 15:02:47
 * @LastEditors: zzj
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\CompanyIntroduction\company-profile\CompanyProfile.js
 */

import '../../../global.js';
import React from 'react';
//import './index.css';
import './company-profile.less'

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Card from '../components/card/Card'

let profileVideo = global.config.url + '/NewGrand/media/newgrand20180913.da890242.mp4';


//第一个模块
//网页第一个模块
class FirstPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,//默认不选择
        }

    }
    playvideo(isture) {
        this.setState({
            isShow: isture,
        });
    }

    render() {
        const isShow = this.state.isShow;
        let myvideo;
        if (isShow === true) {
            myvideo =
                <div className="first-page-vedio-box">
                    <div className="first-page-vedio-bd" onClick={() => this.playvideo(false)}></div>
                    <div className="first-page-vedio-margin">
                        <div className="first-page-video-exit" onClick={() => this.playvideo(false)}>
                            ×
                        </div>
                    </div>
                    <div className="first-page first-page-bg"></div>
                    {/* <div className="first-page-vedio">
                        <video autoPlay="autoplay" loop="loop" >
                            <source src="movie.ogg" type="video/ogg" />
                            <source src={profileVideo} type="video/mp4" />
                            <source src="movie.webm" type="video/webm" />
                            <object data="movie.mp4" width="100%" height="100%">
                                <embed width="100%" height="100%" src="movie.swf" />
                            </object>
                        </video>
                    </div> */}
                </div>
        } else {
            myvideo = <div></div>
        }

        return (
            <div className="first-page first-page-bg"></div>
            // <div className="first-page">
            //     <video autoPlay="autoplay" loop="loop" >
            //         <source src="movie.ogg" type="video/ogg" />
            //         <source src={profileVideo} type="video/mp4" />
            //         <source src="movie.webm" type="video/webm" />
            //         <object data="movie.mp4" width="100%" height="100%">
            //             <embed width="100%" height="100%" src="movie.swf" />
            //         </object>
            //     </video>
            //     {/*视频播放 */}
            //     {myvideo}
            //     <div className="first-page-show">
            //         {/* 
            //         <div className="first-page-show-logo"></div>
            //         <div className="first-page-show-text">
                     
            //         </div>
            //         <div className="first-page-show-icon" onClick={() => this.playvideo(true)}></div>
            //     */}
            //     </div>
            // </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <div className="second-page-title">
                    力争成为全球领先的项目管理数字化平台服务商
                </div>
                <div className="second-page-text">
                    <div className="second-page-intend">
                        杭州新中大科技股份有限公司（简称“新中大”）是一家以项目管理平台及云服务软件的研究、开发和销售为核心，集运维、技术咨询服务于一体的工程建设行业信息化企业。
                    </div>
                    <div className="second-page-intend">
                        新中大以引领工程建设产业数字化转型升级为己任，凭借长期积淀的技术创新能力，形成了以“基于云原生微服务架构的钱潮技术平台NG3.0”为底层支撑，面向工程建设行业、政务行业、制造及服务业等不同下游客户的三大产品线，构建了方案式平台软件、云服务式软件、智能建造解决方案和运维、技术咨询服务等多种形态与模式并行的产品和服务体系。
                    </div>
                    <div className="second-page-intend">
                        截至目前，新中大形成了由11个大区（区域管理与市场管理）、100多家渠道合作伙伴（经销销售与服务）构成的多元市场、营销与服务网络，累计已为200余家施工总承包特级资质企业、1000多家建筑施工企业、十多万家政府机构与制造企业客户提供IT规划与咨询、产品销售、产品实施、技术支持等服务。2020年，根据赛迪顾问《中国工程建设项目管理软件市场研究（2020年）》，公司在特级资质施工企业抽样调查中市场份额排名第一。2023年，新中大“i8工程企业管理软件”作为国内工程项目管理软件领域优秀典型代表产品，成功入选工信部2022年工业软件优秀产品名单。
                    </div>
                </div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <div className="third-page-col">
                    <div className="interval-col-156"></div>
                    <div>
                        <div className="interval-85" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E5%85%AC%E5%8F%B8%E7%AE%80%E4%BB%8B/Photo-1.png"}
                            title={"工程项目管理"} text={"在工程项目管理领域，新中大打造以i8工程项目管理平台、i8c工程项目管理云平台、mi8工程项目管理云平台为代表的工程项目管理软件产品，着力解决工程建设行业多组织层级、多专业板块、多业务模式、多管控模式、多项目关联方、多系统集成的复杂业务场景带来的数字化痛点；以D6C为代表的智能建造产品融合了云计算、边缘计算、BIM、移动互联、GIS等技术，构建面向工地现场的“云平台+物联网”系统；在工程企业咨询服务领域，为企业提供管理提升与数字化转型所需要的全面咨询、培训服务和技术支持方案。"} />
                        <div className="interval-50" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E5%85%AC%E5%8F%B8%E7%AE%80%E4%BB%8B/Photo-4.png"}
                            title={"政务管理"} text={"新中大致力于通过信息技术帮助政府部门提升管控效率，面向农村、工会、政府单位提供N系列农村经营管理软件、H系列工会财务管理软件、G系列财政财务管理软件产品。"} />
                    </div>
                    <div className="interval-col-50"></div>
                    <div>
                        <div className="interval-85" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E5%85%AC%E5%8F%B8%E7%AE%80%E4%BB%8B/Photo-3.png"}
                            title={"制造与服务业管理"} text={"新中大在制造与服务业业务领域主要为客户提供ERP/URP企业管理软件及财务管理软件，包括面向项目服务型企业的i6s系统，面向制造业企业的i6系统，以及面向中小型客户的其他轻量化、标准化的企业管理软件和电子商务门户软件。"} />
                        <div className="interval-50" />
                    </div>
                </div>
            </div>
        );
    }
}
//网页主模块
class CompanyProfile extends React.Component {
    render() {
        return (
            <div>
                <Head />
                <div className="company-profile">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default CompanyProfile;