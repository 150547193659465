import { useEffect } from 'react';
import './index.less';
import React from 'react';
import { useRef } from 'react';

export default ({ videoProps, onVideoClick = () => 0, src }) => {
  const handleClick = () => {
    onVideoClick(src);
  };
  const videoRef = useRef();
  useEffect(() => {
    window.addEventListener('click', () => {
      videoRef.current.muted = false;
    });
  }, []);

  // playsInline 适配ios
  return (
    <div className="video-v-2">
      <video autoPlay loop playsInline muted onClick={handleClick} {...videoProps} ref={videoRef}>
        <source src="movie.ogg" type="video/ogg" />
        <source src={src} type="video/mp4" />
        <source src="movie.webm" type="video/webm" />
        <object data="movie.mp4" width="100%" height="100%">
          <embed width="100%" height="100%" src="movie.swf" />
        </object>
      </video>
    </div>
  );
};
