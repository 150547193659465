import { useRef } from 'react';
import './index.less';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Animation from '../../transition/animation/animation';

export default (props) => {
  const state = props;
  const ref = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    let animationFinash = false;
    const animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        setStartAnimation(true);
        animationFinash = true;
      }
    };
    animationFn();
    window.$(window).scroll(animationFn);
    return () => {
      window.$(window).unbind('scroll', animationFn);
    }
  }, []);

  return (
    <div className={`text-panel-item moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`} ref={ref}>
      <div className="text">
        <span>{state.describe}</span>
      </div>
    </div>
  );
};
