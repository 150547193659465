import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/19/`;
export default {
  sessionA: {
    imgSrc: `img/product_jiancai_banner_pic01.png`,
    describe: "建材贸易行业是一个与国家经济发展紧密相连、具有广阔前景但同时充满挑战的领域，要求企业具备敏锐的市场洞察 力、高效的供应链管理能力以及持续的创新意识，基于建材贸易行业的发展需求，新中大为企业提供了一套完善的业务管理平台。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML: '建筑建材贸易企业数字化',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  //方案特点
  sessionB: {
    rowCount: 1,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '集中统一管理强化采购规范，定期评价'
      },
      {
        key: 1,
        iconPosition: { x: 4, y: 1 },
        describe: '提供集采模式、分散混合采购模式多种管理'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        describe: '自动生成材料采购结算单和费用计算单'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        describe: '实现项目——合同—-出库—销售结算—收款的全流程管控，集成一键开票系统'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },
  sessionC: {
    imgSrc: `img/project_pic06.jpg`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 0 },
        // title: '互联互通，共享共治',
        describe: '实现采购流程的规范化管理'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 0 },
        // title: '制度流程化，流程信息化',
        describe: '为财务核算提供便利'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 0 },
        // title: '随时随地，移动办公',
        describe: '实现从订单到收款的集成业务全流程管理'
      },
      {
        key: 4,
        iconPosition: { x: 8, y: 0 },
        // title: '知识管理，创造价值',
        describe: '提升销售业务效率'
      }
    ]
  },
  sessionE: {
    title: '山东城航供应链集团有限公司',
    describe: `山东城航供应链集团有限公司成立于2023年02月，为济南城市建设集团全资子公司，是建设集团供应链经济产业链主要组成单位之一。随着山东城航供应链集团有限公司业务的快速发展，规模不断扩大，面临的管理问题表现得日益突出，缺乏有效的“业务管理平台系统”进行管理，在集团整体信息化搭建的背景下，传统管理方式缺点愈发突出，为了提升业务管理人员的业务管理效率，增强数据统计的准确性，高效性。顺应企业管理要求，为满足企业未来 5-10年发展，整个信息化系统业务管理平台需架构灵活、设计领先、技术稳定，与企业业务深度融合，并具有随需拓展等能力实现数据集中管理，信息多维分析，提升对领导决策的支撑能力，把握市场机会，规避企业风险。`,
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}