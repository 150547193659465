/**
 * 全名：律师 
 * 功能描述： 
 * 创建日期：--
 * 作者：hzw
 */

import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import ContactBottom from '../../../new-components/ContactBottom';

import ArchitectureImage from '../privateComponents/ArchitectureImage';
import ValuePanel from '../privateComponents/FinanceValuePanel';
import RealCase from '../privateComponents/RealCase';
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';

import config from "./config"
import './index.less';

const SessionBWithTitle = RenderWithTitle(() => <div className="session-b"><img src={config.sessionB.imgSrc} alt="" /></div>, "混凝土生产企业数字化8大现状");
const SessionCWithTitle = RenderWithTitle(() => <div className="session-b"><img src={config.sessionC.imgSrc} alt="" /></div>, "混凝土生产经营结算一体化平台");
const SessionDWithTitle = RenderWithTitle(() => <div className="session-b"><img src={config.sessionD.imgSrc} alt="" /></div>, "新中大混凝土生产经营结算管控平台建设目标");

export default class extends React.Component {
  render() {
    const RealCaseWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(RealCase, '更多案例 >', {}, () => {
      this.props.history.push('/caseof25/项目服务/业务领域');
    }), '用户案例');
    return (
      <div className="epc">
        <Head />
        <Abstract {...config.sessionA} />
        <SessionBWithTitle {...config.sessionB} />
        <SessionCWithTitle {...config.sessionC} />
        <SessionDWithTitle {...config.sessionD} />
        <RealCaseWithTitleAndMoreBtn {...config.sessionE} />
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}