import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import "./App.css";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./transition/up/up.less";
import "./transition/size/size.less";
import "./transition/change-color/color.less";
import "./transition/add-shadow/shadow.less";
import "./transition/animation/animation.less";

import Guide from "./common-components/guide/Guide";
import ScrollToTop from "./common-components/scroll-to-top/ScrollToTop";
//以下是网站的路由表
import Home from "./pages/home/Home"; //首页
//----------项目投资类
// import WorkShop from './pages/projectInvestment/work-shop/WorkShop'  //厂房新建（搬迁）信息化解决方案
import LargeProject from "./pages/projectInvestment/large-project/LargeProject"; //大型工程投资项目信息化解决方案
// import ProjectInvestment from './pages/projectInvestment/project-investment/ProjectInvestment'  //工程投资企业信息化解决方案
//----------项目建造类
// import ProfessionalContract from './pages/ProjectConstruction/professional-contract/ProfessionalContract'  //专业承包企业信息化解决方案
// import ProjectContracting from './pages/ProjectConstruction/project-contracting/ProjectContracting'  //工程总承包企业信息解决方案
// import ConstructionContract from './pages/ProjectConstruction/construction-contract/ConstructionContract'  //施工承包企业信息化解决方案
//----------项目服务类
// import ProcessConsulting from './pages/ProjectService/process-consulting/ProcessConsulting'  //全过程工程咨询服务解决方案
// import LawyerProfession from './pages/ProjectService/lawyer-profession/LawyerProfession'  //律师行业解决方案
// import SoftwareService from './pages/ProjectService/software-service/SoftwareService'  //软件服务于系统集成行业解决方案
//----------集团管控类
// import CooperativeOffice from './pages/groupControl/cooperative-office/CooperativeOffice' //协同办公
// import TaxAdministration from './pages/groupControl/tax-administration/TaxAdministration' //税务管理
// import HumanResources from './pages/groupControl/human-resources/HumanResources' //集团人力资源
// import GroupFinance from './pages/groupControl/group-finance/GroupFinance' //集团财务
//----------数字工地类
// import RealNameLaborManager from './pages/digitalSite/real-name-labor-management/RealNameLaborManager' //企业级劳务实名制用工管理
// import VisualChemicalSiteManagement from './pages/digitalSite/visual-chemical-site-management/VisualChemicalSiteManagement' //可视化工地管理
import SafetyEducation from "./pages/digitalSite/safety-education/SafetyEducation"; //安全教育
// import SiteCore from './pages/digitalSite/site-core/SiteCore' //工地芯
import InterlligentPrintingControl from "./pages/digitalSite/interlligent-printing-control/InterlligentPrintingControl"; //智能印控
// import MachineryManagement from './pages/digitalSite/machinery-management/MachineryManagement' //机械管理
// import MaterialManagement from './pages/digitalSite/material-management/MaterialManagement' //物资管理
//---------加入我们
import TalentRecruitment from "./pages/join-us/talent-recruitment/TalentRecruitment"; //人才招聘
import JoinNG from "./pages/join-us/join-NG/JoinNG"; //加入新中大
import EmployeeLife from "./pages/join-us/employee-life/EmployeeLife"; //员工生活
import CareerDevelopment from "./pages/join-us/career-development/CareerDevelopment"; //职业发展
import JobDetails from "./pages/join-us/jobDetails/JobDetails"; //岗位详情
//----------联系我们
import ContactUs from "./pages/contact-us/ContactUs"; //联系我们
import ContactUs2 from "./pages/contact-us/ContactUs2"; //联系我们2
//-----------伙伴
import PartnerSupport from "./pages/partner/partner-support/PartnerSupport"; //伙伴支持
import SystemIntroduction from "./pages/partner/system-introduction/SystemIntroduction"; //体系介绍
import JoinApply from "./pages/partner/join-apply/JoinApply"; //加盟申请
import CooperationGuide from "./pages/partner/cooperation-guide/CooperationGuide"; //合作指南
import QualificationEnquiry from "./pages/partner/qualification-enquiry/QualificationEnquiry"; //资质查询
//-----------公司介绍（暂缺）
import ManagementLayer from "./pages/CompanyIntroduction/management-layer/ManagementLayer"; //管理层
import CorporateCulture from "./pages/CompanyIntroduction/corporate-culture/CorporateCulture"; //企业文化
import CompanyProfile from "./pages/CompanyIntroduction/company-profile/CompanyProfile"; //公司简介
import CorporateHonor from "./pages/CompanyIntroduction/corporate-honor/CorporateHonor"; //公司荣誉
import DevelopmentHistory from "./pages/CompanyIntroduction/development-history/DevelopmentHistory"; //发展历程

//-----------产品
// import A3 from './pages/products/a3/A3' //a3
// import GE from './pages/products/GE/GE' //GE
// import i6s from './pages/products/i6s/i6s' //i6s
// import I8 from './pages/products/i8/I8' //i8
// import Mini from './pages/products/mini/Mini' //mini
// import Se from './pages/products/se/Se' //se
// import UIC from './pages/products/UIC/UIC' //供应商门户UC
// import UP from './pages/products/UP/UP' //柔性化统一平台up
// import I6 from './pages/products/i6/i6'  //i6
// import MobileApplication from './pages/products/MobileApplication/MobileApplication'  //移动应用

//-----未分组
// import ProjectManufacturing from './pages/ungrouped/project-manufacturing/ProjectManufacturing' //项目制造
import News from "./pages/ungrouped/news/New"; //新闻页面
import Search from "./pages/ungrouped/search/Search"; //搜索页面
import Case from "./pages/ungrouped/case/Case"; //案例页面
import More from "./pages/ungrouped/more/More"; //更多公司信息
import Error from "./pages/home/error/Error"; //未建设提示页面

//-----导航页
import GroupControl from "./pages/overview/groupControl/GroupControl"; //集团管控
import DigitalSite from "./pages/overview/digitalSite/DigitalSite"; //数字工地
import ProjectService from "./pages/overview/projectService/ProjectService"; //项目服务
import Projectinvestment from "./pages/overview/projectInvestment/ProjectInvestment"; //项目投资
import ProjectConstruction from "./pages/overview/projectConstruction/ProjectConstruction"; //项目建造
import NewsDetails from "./pages/ungrouped/newsDetails/NewsDetails"; //新闻详情
import CaseDetails from "./pages/ungrouped/caseDetails/CaseDetails"; //案例详情
import SpecialSolutions from "./pages/overview/SpecialSolutions/SpecialSolutions"; //专项解决方案导航

//----专项解决方案
import SafetyRing from "./pages/SpecialSolutions/SafetyRing/SafetyRing"; //安置环
import CostControl from "./pages/SpecialSolutions/CostControl/CostControl"; //成本管理
import EnterpriseManagement from "./pages/SpecialSolutions/EnterpriseManagement/EnterpriseManagement"; //企业管控
// import HRManage from './pages/SpecialSolutions/HRManage/HRManage' //人力资源管控
import Live from "./pages/SpecialSolutions/Live/Live"; //网络直播
import ProjectManage from "./pages/SpecialSolutions/ProjectManage/ProjectManage"; //项目管控
import COffice from "./pages/SpecialSolutions/CooperativeOffice/COffice"; //协同办公

import Newi8 from "./new-pages/products/i8";
import Newi6s from "./new-pages/products/i6s";
import Newi8s from "./new-pages/products/i8s";
import Newi8m from "./new-pages/products/i8m";
import D6e from "./new-pages/products/D6e";
import Newi6 from "./new-pages/products/i6";
import Newmini from "./new-pages/products/mi8";
import Mini6 from "./new-pages/products/mi6";
import Newa3 from "./new-pages/products/a3";
import NewGE from "./new-pages/products/GE";
import Newse from "./new-pages/products/se";
import NewUIC from "./new-pages/products/UIC";
import NewSUP from "./new-pages/products/SUP";
import Newiot from "./new-pages/products/iot";
import Newi8c from "./new-pages/products/i8c/i8c";
import QianChao from "./new-pages/products/qianchao";
import D6C from "./new-pages/products/D6C";

import GroupFinance from "./new-pages/cases/financeGroup"; //集团财务
import FinanceShare from "./new-pages/cases/financeShare";
import FinanceManage from "./new-pages/cases/financeManage";
import FinancialAccounting from "./new-pages/cases/financialAccounting";
import FinanceControl from "./new-pages/cases/financeControl";

import CooperativeOffice from "./new-pages/cases/officeManage"; //协同办公
import TaxAdministration from "./new-pages/cases/taxManage"; //税务管理
import HumanResources from "./new-pages/cases/hrManage"; //集团人力资
import epc from "./new-pages/cases/epc"; // epc项目总承包企业
import onsiteIns from "./new-pages/cases/onsiteIns"; // epc项目总承包企业
import hunningtu from "./new-pages/cases/hunningtu"; // 混凝土生产制造企业
import goujian from "./new-pages/cases/goujian"; // 建筑建材构件生产企业
import jiancai from "./new-pages/cases/jiancai"; // 建材贸易企业
// import OfficeManage from './new-pages/cases/officeManage'
// import HRManage from './new-pages/cases/hrManage'
// import TaxManage from './new-pages/cases/taxManage'

import MobileApplication from "./new-pages/cases/mobileApp";

import ProjectInvestment from "./new-pages/cases/projectInvestmentManage"; //工程投资企业信息化解决方案
import WorkShop from "./new-pages/cases/workShop"; //厂房新建（搬迁）信息化解决方案
import ConstructionContract from "./new-pages/cases/projectContracting"; //施工承包企业信息化解决方案

// import ConstructionContract from './pages/ProjectConstruction/construction-contract/ConstructionContract'  //施工承包企业信息化解决方案
import ProfessionalContract from "./new-pages/cases/professionalContract"; //专业承包企业信息化解决方案
import ProjectManufacturing from "./new-pages/cases/projectManufacturing"; //项目制造

import LawyerProfession from "./new-pages/cases/lawyerProfession"; //律师行业解决方案
import SoftwareService from "./new-pages/cases/softwareService"; //软件服务于系统集成行业解决方案
import ProcessConsulting from "./new-pages/cases/processConsulting"; //全过程工程咨询服务解决方案
import VisualChemicalSiteManagement from "./new-pages/cases/standardizationConstruction"; //标化工地解决方案
// import VisualChemicalSiteManagement from './pages/digitalSite/visual-chemical-site-management/VisualChemicalSiteManagement' //可视化工地管理
import MaterialManagement from "./new-pages/cases/materielManage"; //物资管理
import RealNameLaborManager from "./new-pages/cases/realNameLaborManager"; //企业级劳务实名制用工管理
import MachineryManagement from "./new-pages/cases/machineryManagement"; //机械管理

import PrivacyPolicy from "./pages/privacyPolicy";
import PrivacyPolicyApp from "./pages/privacyPolicy/App";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Guide />
        <div>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />

              <Route path="/workshop" component={WorkShop} />
              <Route path="/largeproject" component={LargeProject} />
              <Route path="/projectInvestment" component={ProjectInvestment} />

              <Route
                path="/professionalContract"
                component={ProfessionalContract}
              />
              {/* <Route path="/projectContracting" component={ProjectContracting} /> */}
              <Route
                path="/constructionContract"
                component={ConstructionContract}
              />

              <Route path="/processConsulting" component={ProcessConsulting} />
              <Route path="/lawyerProfession" component={LawyerProfession} />
              <Route path="/softwareService" component={SoftwareService} />

              <Route path="/cooperativeOffice" component={CooperativeOffice} />
              <Route path="/taxAdministration" component={TaxAdministration} />
              <Route path="/humanResources" component={HumanResources} />
              <Route path="/groupFinance" component={GroupFinance} />

              <Route
                path="/realNameLaborManager"
                component={RealNameLaborManager}
              />
              <Route
                path="/visualChemicalSiteManagement"
                component={VisualChemicalSiteManagement}
              />
              <Route path="/safetyEducation" component={SafetyEducation} />
              {/* <Route path="/siteCore" component={SiteCore} /> */}
              <Route
                path="/interlligentPrintingControl"
                component={InterlligentPrintingControl}
              />
              <Route
                path="/machineryManagement"
                component={MachineryManagement}
              />
              <Route
                path="/materialManagement"
                component={MaterialManagement}
              />

              <Route path="/talentRecruitment" component={TalentRecruitment} />
              <Route path="/joinNG" component={JoinNG} />
              <Route path="/employeeLife" component={EmployeeLife} />
              <Route path="/careerDevelopment" component={CareerDevelopment} />
              <Route path="/jobDetails/:id" component={JobDetails} />

              <Route path="/contactUs" component={ContactUs} />
              <Route path="/contactUs2" component={ContactUs2} />

              <Route path="/partnerSupport" component={PartnerSupport} />
              <Route
                path="/systemIntroduction"
                component={SystemIntroduction}
              />
              <Route path="/joinApply" component={JoinApply} />
              <Route path="/cooperationGuide" component={CooperationGuide} />
              <Route
                path="/qualificationEnquiry"
                component={QualificationEnquiry}
              />

              <Route path="/managementLayer" component={ManagementLayer} />
              <Route path="/corporateCulture" component={CorporateCulture} />
              <Route path="/companyProfile" component={CompanyProfile} />
              <Route path="/corporateHonor" component={CorporateHonor} />
              <Route
                path="/developmentHistory"
                component={DevelopmentHistory}
              />

              {/* <Route path="/a3" component={A3} /> */}
              {/* <Route path="/GE" component={GE} /> */}
              {/* <Route path="/i6s" component={i6s} /> */}
              {/* <Route path="/i8" component={I8} /> */}
              {/* <Route path="/mini" component={Mini} /> */}
              {/* <Route path="/se" component={Se} /> */}
              {/* <Route path="/UIC" component={UIC} /> */}
              {/* <Route path="/UP" component={UP} /> */}
              {/* <Route path='/I6' component={I6} /> */}
              <Route path="/mobileApplication" component={MobileApplication} />

              <Route
                path="/projectManufacturing"
                component={ProjectManufacturing}
              />

              <Route path="/news" component={News} />
              <Route path="/news1/:size" component={News} />
              <Route path="/news2/:size" component={News} />
              <Route path="/news3/:size" component={News} />
              <Route path="/news4/:size" component={News} />

              <Route path="/search" component={Search} />
              <Route path="/case/:mycase" component={Case} />
              <Route path="/caseof11/:mytype/:mycase" component={Case} />
              <Route path="/caseof12/:mytype/:mycase" component={Case} />
              <Route path="/caseof13/:mytype/:mycase" component={Case} />
              <Route path="/caseof14/:mytype/:mycase" component={Case} />
              <Route path="/caseof15/:mytype/:mycase" component={Case} />
              <Route path="/caseof16/:mytype/:mycase" component={Case} />
              <Route path="/caseof17/:mytype/:mycase" component={Case} />
              <Route path="/caseof18/:mytype/:mycase" component={Case} />
              <Route path="/caseof21/:mytype/:mycase" component={Case} />
              <Route path="/caseof22/:mytype/:mycase" component={Case} />
              <Route path="/caseof23/:mytype/:mycase" component={Case} />
              <Route path="/caseof24/:mytype/:mycase" component={Case} />
              <Route path="/caseof25/:mytype/:mycase" component={Case} />
              <Route path="/caseof26/:mytype/:mycase" component={Case} />
              <Route path="/caseof27/:mytype/:mycase" component={Case} />
              <Route path="/more" component={More} />

              <Route path="/groupControl" component={GroupControl} />
              <Route path="/digitalSite" component={DigitalSite} />
              <Route path="/projectService" component={ProjectService} />
              <Route path="/investment" component={Projectinvestment} />
              <Route
                path="/projectConstruction"
                component={ProjectConstruction}
              />
              <Route path="/newsDetails/:id" component={NewsDetails} />
              <Route path="/caseDetails/:id" component={CaseDetails} />
              <Route path="/error" component={Error} />
              <Route path="/specialSolutions" component={SpecialSolutions} />

              <Route path="/safetyRing" component={SafetyRing} />
              <Route path="/costControl" component={CostControl} />
              <Route
                path="/enterpriseManagement"
                component={EnterpriseManagement}
              />
              <Route path="/live" component={Live} />
              <Route path="/projectmanage" component={ProjectManage} />
              <Route path="/cOffice" component={COffice} />

              <Route path="/i8" component={Newi8} />
              <Route path="/i6s" component={Newi6s} />
              <Route path="/i8s" component={Newi8s} />
              <Route path="/i8m" component={Newi8m} />
              <Route path="/d6e" component={D6e} />
              <Route path="/i6" component={Newi6} />
              <Route path="/a3" component={Newa3} />
              <Route path="/mini" component={Newmini} />
              <Route path="/mi6" component={Mini6} />
              <Route path="/GE" component={NewGE} />
              <Route path="/se" component={Newse} />
              <Route path="/UIC" component={NewUIC} />
              <Route path="/UP" component={NewSUP} />
              <Route path="/qianchao" component={QianChao} />
              <Route path="/d6c" component={D6C} />
              <Route path="/i8c" component={Newi8c} />
              <Route path="/epc" component={epc} />
              <Route path="/siteCore" component={Newiot} />
              <Route path="/onsiteIns" component={onsiteIns} />
              <Route path="/financeShare" component={FinanceShare} />
              <Route path="/financeManage" component={FinanceManage} />
              <Route
                path="/financialAccounting"
                component={FinancialAccounting}
              />
              <Route path="/financeControl" component={FinanceControl} />
              <Route path="/privacyPolicy" component={PrivacyPolicy}></Route>
              <Route path="/privacyPolicyApp" component={PrivacyPolicyApp}></Route>
              <Route path="/hunningtu" component={hunningtu}></Route>
              <Route path="/goujian" component={goujian}></Route>
              <Route path="/jiancai" component={jiancai}></Route>
              <Redirect to="home"></Redirect>
            </Switch>
          </ScrollToTop>
        </div>
      </Router>
    );
  }
}

export default App;
