import "../../../global.js";

export default {
  // 简介
  sessionA: {
    imgSrc: `img/product_i8s_banner_pic01.png`,
    describe: "i8s企业管理平台是适合于按项目提供技术服务的集团企业管理应用平台；打造以项目服务为核心的，涵盖客户关系管理、合同管理、供应链管理、项目管理、发票税务管理、费控中心，项目成本管理、集团财务管理、人力资源管理、协同办公管理和知识库管理一站式企业管理服务平台。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "i8s企业管理平台",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 5,
    rowCount: 1,
    default: {
      backgroundImage: `url(img/product_icon_i8s.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        describe: '集团企业全过程管控平台'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 0 },
        describe: '项目服务管理平台'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 0 },
        describe: '以合同与成本为中心的全程项目管控平台'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 0 },
        describe: '全新WEB架构全面移动应用'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 0 },
        describe: '柔性化平台，管理随需而变'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `img/product_mokuai_i8s.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(img/product_icon_i8s.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 1 },
        title: '客户关系管理',
        describe: '客户关系管理系统是一种旨在改善企业与客户之间关系的新型互动管理平台，客户关系管理套件旨在选择和管理有价值客户及其关系，通过以客户为中心的企业文化来支持有效的市场营销、销售与服务流程。'
      },
      {
        key: 2,
        iconPosition: { x: 7, y: 1 },
        title: '合同管理',
        describe: '合同管理提供了对企业各种类型合同进行有效管理的功能，涉及合同要素、合同附件、合同付款计划、合同结算明细、合同变更信息、合同执行情况等管理要素。'
      },
      {
        key: 3,
        iconPosition: { x: 8, y: 1 },
        title: '供应链管理',
        describe: '供应链管理系统是以供应链管理为主线，对企业的订单接收、采购、库存管理、生产、分销、销售等环节进行控制和管理，最终实现企业与供应商、分销商、终端客户的协同。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '项目管理',
        describe: '围绕项目建立从立项、策划、项目团队、项目进度管理、项目任务管理、项目进度填报、项目进度成本控制平台、项目文档管理全过程项目管理体系，优化企业项目管理流程。'
      },
      {
        key: 5,
        iconPosition: { x: 1, y: 2 },
        title: '成本管理',
        describe: '成本管理是遵循企业成本控制的业务流程，参考管理会计思想而设计研发的帮助企业实时掌握产品、服务等成本中心的动态成本管理模块。用户可以定义产品、服务二种不同的成本对象和相关的成本项目，在计算机内按费用中心记录各项费用的台账，并及时将有关凭证转到总账系统。成本管理可实现标准成本和作业成本的管理和分析。'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 1 },
        title: '集团财务管理',
        describe: '以预算管理为核心的集团财务控制体系。系统应用分为三层：基础核心层包括总账、现金中心、客户中心、供应商中心、报表中心、合同管理等系统，构成企业会计核算基础；专项业务管理层包括成本管理、固定资产管理、结算中心，企业可根据实际业务需要选择以建立企业财务管理与控制体系；决策管理层包括预算管理、经营分析、集团管理等系统，满足企业高层财务管理的需要。'
      },
      {
        key: 7,
        iconPosition: { x: 0, y: 2 },
        title: '发票税务管理',
        describe: '发票税务管理主要针对进项发票和销项发票的发票管理，以及纳税申报的税务管理。通过系统实现全企业发票电子化、网络化、集中管控，提升税票统筹能力；提供发票扫描录入、影像管理、自动化税务抵扣认证，提高增值税发票处理效率，节约企业税务管理成本；实现税务统筹、税款预缴、纳税申报、企业基准税负分析等功能，辅助企业实现计缴申报；通过项目基准税负分析和项目税负看板实现对项目的税负分析。'
      },
      {
        key: 8,
        iconPosition: { x: 8, y: 1 },
        title: '费控中心',
        describe: '费控中心，通过核心报账机器人的应用，完成线上报销自动识别、查验管控、价税分离、自动分析、智能填单，并与其他业务系统（如：项目成本、发票税务管理、合同管理、项目资金、集团财务、银企直联等）无缝衔接打通，达到项目成本管控、旅客运输发票推送、费用合同集成、项目资金控制、业财一体化、一键支付等目标。'
      },
      {
        key: 9,
        iconPosition: { x: 2, y: 2 },
        title: '柔性化统一平台',
        describe: '新中大柔性化统一平台SUP以项目管理为核心，基于企业服务总线ESB的大型企业管理信息化系统建设和管理软件开发为目的，提供可配置、可重构的基础应用中间件平台与关键支撑的工具集。应用于信息化项目实施团队快速、低成本、高可靠地开发和部署项目服务型企业信息化管理系统。'
      },
      {
        key: 10,
        iconPosition: { x: 10, y: 1 },
        title: '协同办公管理',
        describe: '协同办公模块主要包括个人事务管理、日常办公管理、内部协同管理、文档知识管理、档案管理、流程监控管理六个功能模块,实现企业内部信息协同及管理协同，提高企业管理效率。'
      },
      {
        key: 11,
        iconPosition: { x: 2, y: 1 },
        title: '人力资源管理',
        describe: '通过人力资源管理系统能够对组织内外相关人力资源进行有效运用，满足组织当前及未来发展的需要，保证组织目标实现与成员发展的最大化，有效降低企业的人力资源运营成本。人力资源管理系统主要包括组织管理、人事档案管理、员工事务管理、员工招聘管理、员工考勤管理、薪资福利管理、员工培训管理、员工绩效管理、员工职业规划等。'
      }
    ]
  },

  // sessionF: {
  //   items: [
  //     {
  //       logoSrc: `${baseUrl}a.png`,
  //       history: '/caseDetails/1139'
  //     },
  //     {
  //       logoSrc: `${baseUrl}b.png`,
  //       history: '/caseDetails/1140'
  //     },
  //     {
  //       logoSrc: `${baseUrl}c.png`,
  //       history: '/caseDetails/1141'
  //     }
  //   ]
  // },

  sessionG: {
    title: '鑫桥文旅集团',
    describe: '鑫桥文旅集团，致力于成为国内领先的文旅产业综合服务商，以强大的资源整合能力和优质的专业服务引领行业变革。鑫桥文旅坚持“品质为先”的品牌主张，基于“文旅+”的业务模式，在以“文旅”为核心的基础上，孵化鑫桥会展、新世纪国旅、集凯教育、云笑科技、云上叮咚等多家子公司，形成集旅游出行、会议会展、文创产业、教育、酒店管理、数字科技于一体的综合服务平台。上游加强产业整合，下游提升服务内涵，实现了资源的跨界共享、合作共赢。旅游出行——浙江新世纪国际旅行社有限公司；会展公关——杭州鑫桥会展有限公司；国际教育——杭州集凯教育咨询有限公司；电商运营——杭州云笑信息技术有限公司；数字会展——浙江云上叮咚科技有限公司。',
  }
}