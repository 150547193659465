import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import ContactBottom from '../../../new-components/ContactBottom';
import PointPanel from "../privateComponents/FinancePointPanel";
import ArchitectureImage from '../privateComponents/ArchitectureImage';
import ValuePanel from '../privateComponents/FinanceValuePanel';
import RealCase from '../privateComponents/RealCase';
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';

import config from "./config"
import './index.less';

const SessionCWithTitle = RenderWithTitle(() => <div className="session-b"><img src={config.sessionC.imgSrc} alt="" /></div>, "业务平台整体架构");

export default class extends React.Component {
  render() {
    const RealCaseWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(RealCase, '更多案例 >', {}, () => {
      this.props.history.push('/caseof25/项目服务/业务领域');
    }), '用户案例');
    return (
      <div className="epc">
        <Head />
        <Abstract {...config.sessionA} />
        <PointPanel {...config.sessionB} />
        <SessionCWithTitle {...config.sessionC} />
        <ValuePanel {...config.sessionD} />
        <RealCaseWithTitleAndMoreBtn {...config.sessionE} />
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}