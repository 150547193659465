import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/D6C/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${baseUrl}1.png`,
    describe: "",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML: `项企一体化<br>智能建造全方位管控<br><image style="width:75%;padding-top:30px;" src="${baseUrl}2.png" />`,
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 功能简介
  sessionC: {
    colCount: 6,
    rowCount: 2,
    describe: "企业级智能建造平台是以满足项目现场人、机、料、法、环、测六要素全面监管为目标，围绕人员、环境、物料、进度、质量、安全等多个业务进行智慧管控的系统。同时与i8项目管理系统深度融合，将生产与经营打通，满足“项企融合”深化管理需求、解决数据溯源问题。另外系统支持微信小程序、H5等多种外部协同方式，满足内外数据和信息协同共享需求。最终融合BIM、大数据BI分析，实现企业级、项目级多级指挥调度管理工作模式，为企业决策层提供数据可视化呈现和分析的能力。",
    items: [
      {
        key: 1,
        backgroundImage: `url(${baseUrl}3.png)`,
        describe: '项目管理'
      },
      {
        key: 2,
        backgroundImage: `url(${baseUrl}4.png)`,
        describe: '现场人员管理'
      },
      {
        key: 3,
        backgroundImage: `url(${baseUrl}5.png)`,
        describe: '现场进度管理'
      },
      {
        key: 4,
        backgroundImage: `url(${baseUrl}6.png)`,
        describe: '现场机械管理'
      },
      {
        key: 5,
        backgroundImage: `url(${baseUrl}7.png)`,
        describe: '现场物料管理'
      },
      {
        key: 6,
        backgroundImage: `url(${baseUrl}8.png)`,
        describe: '视频监控管理'
      },
      {
        key: 7,
        backgroundImage: `url(${baseUrl}9.png)`,
        describe: '现场安全管理'
      },
      {
        key: 8,
        backgroundImage: `url(${baseUrl}10.png)`,
        describe: '现场质量管理'
      },
      {
        key: 9,
        backgroundImage: `url(${baseUrl}11.png)`,
        describe: '现场环境管理'
      },
      {
        key: 10,
        backgroundImage: `url(${baseUrl}12.png)`,
        describe: 'BIM融合管理'
      },
      {
        key: 11,
        backgroundImage: `url(${baseUrl}13.png)`,
        describe: '技术工法管理'
      },
      {
        key: 12,
        backgroundImage: `url(${baseUrl}14.png)`,
        describe: '集成管理'
      },
    ]
  },

  // 产品架构图
  sessionD: {
    imgSrc: `${baseUrl}15.png`,
  },

  // 产品特点
  sessionE: {
    colCount: 3,
    rowCount: 2,
    items: [
      {
        key: 1,
        backgroundImage: `url(${baseUrl}16.png)`
      },
      {
        key: 2,
        backgroundImage: `url(${baseUrl}17.png)`
      },
      {
        key: 3,
        backgroundImage: `url(${baseUrl}18.png)`
      },
      {
        key: 4,
        backgroundImage: `url(${baseUrl}19.png)`
      },
      {
        key: 5,
        backgroundImage: `url(${baseUrl}20.png)`
      },
      {
        key: 6,
        backgroundImage: `url(${baseUrl}21.png)`
      },
    ]
  },

  // 应用价值
  sessionF: {
    imgSrc: `${baseUrl}22.png`,
  },

  // 客户案例
  sessionG: {
    items: [
      {
        key: 1,
        backgroundImage: `url(${baseUrl}23.png)`,
        describe: '企业级人员管理'
      },
      {
        key: 2,
        backgroundImage: `url(${baseUrl}24.png)`,
        describe: '智能物资管理'
      },
      {
        key: 3,
        backgroundImage: `url(${baseUrl}25.png)`,
        describe: '标化工地、项企融合'
      },
      {
        key: 4,
        backgroundImage: `url(${baseUrl}26.png)`,
        describe: '观摩工地'
      },
    ]
  }
}