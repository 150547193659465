import React, { useEffect } from 'react';
import { contentInfo, contentList } from './date.js';
import './index.less';

const PrivacyPolicyApp = (props) => {
  useEffect(() => {
    document.getElementsByClassName('common')[0].hidden = true;
    if (document.getElementById('newBridge'))
      document.getElementById('newBridge').style.display = 'none';
  }, [props.location]);

  return <div className="privacy">
    <main className='app-content'>
      <h3>{contentInfo[1].title}</h3>
      <p>{contentInfo[1].update}</p>
      {contentList[3]}
      <p style={{ fontWeight: 'bold'}}>{contentList[4]}</p>
      {contentList[5]}
    </main>
  </div>;
}

export default PrivacyPolicyApp;