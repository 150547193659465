import React, { useState } from "react";
// import Axios from "axios";
import { useHistory } from "react-router-dom";
// import $ from "jquery";

// 组件
import Head from "../../../common-components/TopBar/TopBar.js";
import Bottom from "../../../common-components/BottomBar.js";
import Abstract from "../../../new-components/Abstract/index.js";
import IconItem from "../../../new-components/IconItem/index.js";
import IconTitleItem from "../../../new-components/IconTitleItem/index.js";
import ContactBottom from '../../../new-components/ContactBottom/index.js';
import RealCase from '../components/RealCase/index.js';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle/index.js';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn/index.js';

import './index.less';

// 配置
import config from './config.js';
import "../../../global.js";

function SessionC(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: config.default.backgroundImage,
          backgroundSize: `calc(1000vw/14.4)`,
          backgroundPosition: `calc(${-10 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-10 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
        }} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="session-c"> {inner}</div>
}

function SessionE(config) {
  const colCount = config.colCount;
  const rowCount = config.rowCount;
  const onClick = config.toggleModules;

  const inner = [];

  for (let rowIndex = 0; rowIndex < rowCount; rowIndex += 1) {
    let currRow = [];

    for (let colIndex = 0; colIndex < colCount; colIndex += 1) {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      if (!currItemConfig) {
        break;
      }

      currRow.push(
        <IconTitleItem
          key={currItemConfig.key}
          describe={currItemConfig.describe}
          iconStyle={{
            backgroundImage: config.default.backgroundImage,
            backgroundPosition: `calc(${-25 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-25 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
          }}
          title={currItemConfig.title}
        />
      );
    }

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  }

  return (<div className="session-e">
    {inner}
    <div className="btn">
      {
        (() => {
          if (rowCount === 2) {
            return (
              <div onClick={() => { onClick && onClick("open") }}>
                <div className="down"></div>
                <div className="text">展开</div>
              </div>
            )
          } else {
            return (
              <div onClick={() => { onClick && onClick("close") }}>
                <div className="up" ></div>
                <div className="text">收起</div>
              </div>
            )
          }
        })()
      }
    </div>
  </div>)
}

const SessionCWithTitle = RenderWithTitle(SessionC, "产品特点", 5, 2);

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "产品模块图", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionEWithTitle = RenderWithTitle(SessionE, "系统模块", { backgroundColor: '#F2F5F6' });

const RealCaseWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(RealCase, '', {}, () => {
  this.props.history.push('/caseof21/集团管控/业务领域');
}), '用户案例');

export default () => {
  const [rowCount, setRowCount] = useState(2);
  const colCount = 2;
  const history = useHistory();

  function handleToggleModules(action) {

    if (action === 'open') {
      setRowCount(7);
    } else {
      setRowCount(2);
    }
  }

  return (
    <div className="new-i6-page">

      <Head />

      <Abstract {...config.sessionA} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle {...config.sessionD} />

      <SessionEWithTitle {...config.sessionE} colCount={colCount} rowCount={rowCount} toggleModules={handleToggleModules} />

      <RealCaseWithTitleAndMoreBtn  {...config.sessionG}/>

      <ContactBottom />

      <Bottom />
    </div>
  )
};
