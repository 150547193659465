import React, { useState, useEffect } from 'react';
import Head from '../../common-components/TopBar/TopBar';
import Bottom from '../../common-components/BottomBar';
import { contentInfo, contentList } from './date.js';
import './index.less';

const baseUrl = `${global.config.url}/newgrand/picture/img/privacy/`;

const PrivacyPolicy = (props) => {
  const [dateIndex, setDateIndex] = useState(0);

  useEffect(() => {
    if (props.location && props.location.search) {
      try {
        const type = props.location.search.split('?type=');
        if (type.length === 2) {
          setDateIndex(Number(type[1]));
        } else {
          setDateIndex(0);
        }
      } catch (e) {
        setDateIndex(0);
      }
    }
  }, [props.location]);

  const handleOnChangeIndex = (number) => () => {
    setDateIndex(number);
  }
  return <div className="privacy">
    <Head />
    <img className="picture" src={baseUrl + 'bg1.png'} alt='首页标题' />
    <aside className="guide">
      <div className="guide-title">隐私政策</div>
      <ul className='reset list'>
        <li onClick={handleOnChangeIndex(0)} className={`reset item${dateIndex === 0 ? ` item-active` : ''}`}>隐私政策-新中大云账号</li>
        <li onClick={handleOnChangeIndex(1)} className={`reset item${dateIndex === 1 ? ` item-active` : ''}`}>app隐私-新中大云账号</li>
        <li onClick={handleOnChangeIndex(2)} className={`reset item${dateIndex === 2 ? ` item-active` : ''}`}>新中大用户协议-新中大云账号</li>
      </ul>
    </aside>
    <main className='content'>
      <h3>{contentInfo[dateIndex] && contentInfo[dateIndex].title}</h3>
      <p>{contentInfo[dateIndex] && contentInfo[dateIndex].update}</p>
      {contentList[dateIndex]}
    </main>
    <Bottom />
  </div>;
};
export default PrivacyPolicy;