import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/iot/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%B7%A5%E5%9C%B0%E8%8A%AF/banner.png`,
    describe: "工地芯，一款由杭州新中大科技旗下子公司杭州浩联智能科技有限公司自主研发的“工地物联网网关”，核心使命“万物互联”，搭载“D6 智能建造管理平台-现场端”，采用Linux系统安全稳定，支持TCP、串口、UDP协议，工地芯实现离线应用、联网断点续传，既解决工地网络不稳定问题，也满足企业数据收集监管需求。工地芯标准API开放，兼容主流实名制考勤设备，并可与智能物联厂商接入，为企业项目管理统一规划，创造了有利条件。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "工地芯",
    imgStyle: {
      maxHeight: '100%',
    }
  },
  
  sessionB: {
    src: global.config.url + '/NewGrand/media/site.712cbf80.mp4',
  },

  // 产品特点
  sessionC: {
    colCount: 4,
    rowCount: 1,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '离线应用，不受网络影响'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '联网续传，企业数据同步'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '极简稳定，支持自动更新'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '开放平台，建立生态合作'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '万物互联，开放平台',
        describe: 'D6开放平台，工地芯提供标准接入API，支持各硬件及软件应用接入，“工地芯”力求打造“人、机、料、法、环、测”工地整体物联网平台。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '工人管理',
        describe: '接入市场主流人脸识别考勤设备，可进行人脸采集、身份证信息采集并完成人脸进场权限下发到闸机设备，支持查询工人信息，创建人员信息，进/退场设置、批量导入/导出功能。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '考勤管理',
        describe: '考勤记录本地存储，断点续传进出记录。支持当前实时在场人数查询、班组统计（在场的班组人员数量统计）、考勤统计（可按日期时间查询具体人员的考勤统计及具体考勤情况）、一键下班功能：因停电/故障/未走通道等原因尚未下班的人员进操作一键退场。进出记录查看/筛选/导出、考勤记录多维统计。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '宿舍管理',
        describe: '实名制进场人员进入宿舍分配床位，一人一床酒店式管理，入住情况一目了然。 '
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '智能地磅',
        describe: '智能地磅离线版接入，支持自动车牌识别、自动称重取数、视频监控影像查看、收发料台账信息完善、发货单、收料榜单打印等功能。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '机械管理',
        describe: '机械宝数据接入，支持查看机械宝位置信息、台班信息、油耗数据等功能。'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 1 },
        title: '视频监控',
        describe: '视频监控摄像头IP地址设置、摄像头命名，端口账号配置，摄像头查看等功能。'
      },
      {
        key: 8,
        iconPosition: { x: 7, y: 1 },
        title: '车辆管理',
        describe: '工地现场车辆进出到闸控制、车牌识别、进出信息查询等功能。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1129'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history:'/caseDetails/1059'
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history:'/caseDetails/1127'
      }
    ]
  }
}