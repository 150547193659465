import React from 'react';

// 组件
import Head from '../../../common-components/TopBar/TopBar';
import Bottom from '../../../common-components/BottomBar';
import Abstract from '../../../new-components/Abstract';
import IconItem from '../../../new-components/IconItem';
import IconTitleItem from '../../../new-components/IconTitleItem';
import ContactBottom from '../../../new-components/ContactBottom';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle';

import './index.less';

// 配置
import config from './config';
import '../../../global.js';
import TextPanel from '../../../new-components/TextPanel';
import Video from '../../../new-components/Video';
import { useState } from 'react';

function SessionC(config) {
  const colCount = config.colCount;
  const rowCount = config.rowCount;
  const inner = [];
  const textPanel = config.textPanel;
  const textCol = textPanel.col;
  const textRow = textPanel.row;

  [...Array(rowCount)].forEach((el, rowIndex) => {
    const currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      const currentIndex = rowIndex * colCount + colIndex;
      let currItemConfig = config.items[currentIndex];
      !!currItemConfig &&
        currRow.push(
          <IconItem
            className="item"
            key={currentIndex}
            describe={currItemConfig.describe}
            iconStyle={{
              backgroundImage: config.default.backgroundImage('0' + (currentIndex + 1)),
              backgroundSize: 'cover'
            }}
          />
        );
    });
    inner.push(
      <div className="row" key={rowIndex} style={{}}>
        {currRow}
      </div>
    );
  });
  [...Array(textRow)].forEach((_, rowIndex) => {
    const currRow = [];
    [...Array(textCol)].forEach((_, colIndex) => {
      const currentIndex = rowIndex * textCol + colIndex;
      currRow.push(<TextPanel key={currentIndex} describe={textPanel.items[currentIndex]} />);
    });
    inner.push(
      <div className="row" key={'t' + rowIndex}>
        {currRow}
      </div>
    );
  });

  return <div className="session-c"> {inner} </div>;
}

function SessionE(config) {
  const colCount = config.colCount;
  const rowCount = config.rowCount;

  const inner = [];
  [...Array(rowCount)].forEach((el, rowIndex) => {
    const currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      const currentIndex = rowIndex * colCount + colIndex;
      const currItemConfig = config.items[currentIndex];
      !!currItemConfig &&
        currRow.push(
          <IconTitleItem
            key={currentIndex}
            describe={currItemConfig.describe}
            iconStyle={{
              backgroundImage: config.default.backgroundImage(('0' + (currentIndex + 1)).slice(-2)),
              width: '32.63vw',
              backgroundSize: 'cover',
              marginBottom: '1vw'
            }}
            describeStyle={{
              color: '#000',
              fontWeight: 400
            }}
          />
        );
    });
    inner.push(
      <div className="row" key={rowIndex}>
        {currRow}
      </div>
    );
  });

  return (
    <div className="session-e">
      {inner}
      {/* <div className="btn">
        {rowCount === 2 ? (
          <div onClick={() => onClick && onClick('open')}>
            <div className="down" />
            <div className="text">展开</div>
          </div>
        ) : (
          <div onClick={() => onClick && onClick('close')}>
            <div className="up" />
            <div className="text">收起</div>
          </div>
        )}
      </div> */}
    </div>
  );
}

const SessionCWithTitle = RenderWithTitle(SessionC, '产品特点', 5, 2);

const SessionDWithTitle = RenderWithTitle(
  () => (
    <div className="session-d">
      <img src={config.sessionD.imgSrc} alt="" />
    </div>
  ),
  '产品模块图',
  { background: `#F4FAFE` }
);

const SessionEWithTitle = RenderWithTitle(SessionE, '系统模块', { backgroundColor: '#FFF' });

export default () => {
  const rowCount = 6;
  const colCount = 2;
  const [muted, mutedUpdate] = useState(true);
  window.addEventListener('onclick', () => {
    mutedUpdate(true);
  });

  return (
    <div className="new-mi8-page">
      <Head />

      <Abstract {...config.sessionA} />

      <Video {...config.sessionB} videoProps={{ muted }} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle {...config.sessionD} />

      <SessionEWithTitle {...config.sessionE} colCount={colCount} rowCount={rowCount} />

      <ContactBottom />

      <Bottom />
    </div>
  );
};
