import './index.less';
import React, { useEffect, useRef, useState } from 'react';
import Animation from '../../transition/animation/animation';

export default (props) => {
  const state = props;

  const ref = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  function handleTriggerAnimation() {
    let animationFinash = false;
    let animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        setStartAnimation(true);
        animationFinash = true;
      }
    };
    animationFn();
    window.$(window).scroll(animationFn);
  }

  useEffect(() => {
    handleTriggerAnimation();
  }, []);

  return (
    <div className="icon-text-item" ref={ref}>
      <div
        className={`icon moveup20-initial opacity-initial${startAnimation ? ' moveup20' : ''}`}
        style={{ ...state.iconStyle }}
      />
      <div className="text">
        <span>{state.describe}</span>
      </div>
    </div>
  );
};
