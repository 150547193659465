import React from "react";

// 组件
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import IconItem from "../../../new-components/IconItem";
import ContactBottom from '../../../new-components/ContactBottom';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle';

import './index.less';

// 配置
import config from './config';
import "../../../global.js";

function SessionC(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [
    <div className="text" style={{ textIndent: '2vw' }}>{config.describe}</div>
  ];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: currItemConfig.backgroundImage,
          borderRadius: 'unset',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="session-c"> {inner}</div>
}

function SessionE(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: currItemConfig.backgroundImage,
          borderRadius: 'unset',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="session-e"> {inner}</div>
}

function SessionG(config) {
  const inner = [];
  config.items.forEach((el, rowIndex) => {
    inner.push(<div className="row" key={rowIndex}>
      <div className="desc">{el.describe}</div>
      <IconItem className="item" key={el.key} iconStyle={{
        backgroundImage: el.backgroundImage,
        borderRadius: 'unset',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }} />
    </div>);
  });

  return <div className="session-g"> {inner}</div>
}

const SessionCWithTitle = RenderWithTitle(SessionC, "企业级智能建造平台功能简介", 5, 2);

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "产品模块图", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionEWithTitle = RenderWithTitle(SessionE, "产品特点", { backgroundColor: '#F2F5F6' });

const SessionFWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionF.imgSrc} alt="" /></div>, "应用价值");

const SessionGWithTitle = RenderWithTitle(SessionG, "客户案例", { backgroundColor: '#F2F5F6' });


export default () => {
  return (
    <div className="D6C-page">
      <Head />

      <Abstract {...config.sessionA} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle {...config.sessionD} />

      <SessionEWithTitle {...config.sessionE} />

      <SessionFWithTitle {...config.sessionF} />

      <SessionGWithTitle {...config.sessionG} />

      <ContactBottom />

      <Bottom />
    </div>
  )
};