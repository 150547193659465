/*
 * @Author: your name
 * @Date: 2020-01-06 10:55:13
 * @LastEditTime: 2022-01-12 17:15:58
 * @LastEditors: qyy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\ungrouped\case\Case.js
 */
import React, { useLayoutEffect } from 'react';
//import './index.css';
import './case.less';
import '../../../transition/change-color/color.less'
import $ from 'jquery';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Axios from 'axios';

// import introduceVideo from '../../../picture/新中大20180913.mp4';

class CaseCard extends React.Component {
    render() {
        return (
            <div className="case-card" onClick={this.props.click}>
                <div className="case-card-img">
                    <img src={this.props.img} alt=''></img>
                </div>
                <div className="case-card-text">{this.props.text}</div>
            </div>
        );
    }
}

//网页第一页
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">

            </div>
        );
    }
}

//第二个模块
class SecondPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cases: { "code": 0, "msg": "操作成功", "count": 1, "data": [] },
            arr: [],
            casetype: "", //限制案例的类型      
            productName: "", //产品名
            businessName: "", //业务名                                                        
        }
    }


    componentDidMount() {
        Axios.get(global.config.serverUrl + '/CaseInfo/LoadData/?page=1&limit=1000')
            .then((cases) => {
                this.setState({ cases: cases.data })
            })
            .catch((error) => {
            });
    }
    //显示菜单
    show(id) {
        document.getElementById(id).style.transform = "translateY(" + 70 / 14.4 + "vw)";
        document.getElementById("icon-" + id).style.transform = "rotate(180deg)";
    }
    //收起菜单
    unShow(id) {
        document.getElementById(id).style.transform = "translateY(-7.8611111vw)";
        document.getElementById("icon-" + id).style.transform = "rotate(0deg)";
    }
    render() {
        var casesList1 = [];
        var casesList2 = [];
        var casesList3 = [];
        let caseNum = 0;
        var userfulCases = [];
        for (let i = 0; i < this.state.cases.data.length; i++) {
            if (this.state.cases.data[i].IsUseful === 1) {
                if (this.props.casetype === "all" || this.props.casetype == this.state.cases.data[i].CaseTypeDesc) {
                    //不给casetype的限制条件此行短路输出所有的案例，否则按给出的限制条件输出
                    if (this.props.casetype === "all") {
                        userfulCases[caseNum] = this.state.cases.data[i];
                        caseNum = caseNum + 1;
                    }
                    if (this.props.casetype === "产品" && (this.props.productName < 1 || this.props.productName == this.state.cases.data[i].ProductName)) {
                        userfulCases[caseNum] = this.state.cases.data[i];
                        caseNum = caseNum + 1;
                    }
                    if (this.props.casetype === "业务领域" && (this.props.businessName < 1 || this.props.businessName == this.state.cases.data[i].CaseName)) {
                        userfulCases[caseNum] = this.state.cases.data[i];
                        caseNum = caseNum + 1;
                    }
                }
            }
        }

        var usenum = 0;
        for (let j = 0; j < caseNum; j++) {
            if (usenum % 3 == 0) {
                casesList1.push(<CaseCard key={j + "1"} text={userfulCases[j].CaseTitle}
                    img={global.config.serverUrl + userfulCases[j].CaseFilePath} click={this.props.handleClick(userfulCases[j].Id)} />);
                casesList1.push(<div key={j + "2"} className="interval-60" />);
                usenum = usenum + 1;
            }
            else if (usenum % 3 == 1) {
                casesList2.push(<CaseCard key={j + "3"} text={userfulCases[j].CaseTitle}
                    img={global.config.serverUrl + userfulCases[j].CaseFilePath} click={this.props.handleClick(userfulCases[j].Id)} />);
                casesList2.push(<div key={j + "4"} className="interval-60" />);
                usenum = usenum + 1;
            }
            else {
                casesList3.push(<CaseCard key={j + "5"} text={userfulCases[j].CaseTitle}
                    img={global.config.serverUrl + userfulCases[j].CaseFilePath} click={this.props.handleClick(userfulCases[j].Id)} />);
                casesList3.push(<div key={j + "6"} className="interval-60" />);
                usenum = usenum + 1;
            }

        }
        return (
            <div className="second-page moveup-initial" id="case-second">
                <div className="second-page-top">
                    <div className="second-page-head">
                        <div className="second-page-product" onMouseEnter={() => this.show("product")} onMouseLeave={() => this.unShow("product")}>
                            <div className="second-page-product-text">产品案例</div>
                            <div className="interval-col-8"></div>
                            <div className="head-text-icon" id="icon-product"></div>
                        </div>
                        <div className="second-page-business" onMouseEnter={() => this.show("business")} onMouseLeave={() => this.unShow("business")}>
                            <div className="second-page-business-text">领域案例</div>
                            <div className="interval-col-8"></div>
                            <div className="head-text-icon" id="icon-business"></div>
                        </div>
                    </div>
                    <div className="second-page-product-body" id="product" onMouseEnter={() => this.show("product")} onMouseLeave={() => this.unShow("product")}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("i8工程项目管理平台")}>i8工程项目管理平台</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("mi8工程项目管理云平台")}>mi8工程项目管理云平台</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("i6s企业管理软件")}>i6s企业管理软件</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("i6企业管理软件")}>i6企业管理软件</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("A3企业管理软件")}>企业管理软件A3</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("GE企业管理软件")}>管理软件金色快车GE</div></td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("SE+企业管理软件")}>财务软件银色快车SE</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("柔性统一平台UP")}>柔性化统一平台SUP</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("供应商门户UIC")}>供应商门户UIC</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("i8m企业管理平台")}>i8m企业管理平台</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.productChange("i8s企业管理平台")}>i8s企业管理平台</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="second-page-business-body" id="business" onMouseEnter={() => this.show("business")} onMouseLeave={() => this.unShow("business")}>
                        <table>
                            <tbody>
                                <tr>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("集团管控")}>集团管控</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("项目投资")}>项目投资</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("项目建造")}>项目建造</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("项目制造")}>项目制造</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("项目服务")}>项目服务</div></td>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("数字工地")}>数字工地</div></td>
                                </tr>
                                <tr>
                                    <td><div className="second-page-product-body-text text-yellow" onClick={this.props.businessChange("工程建材")}>工程建材</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="second-page-body">
                    <div className="second-page-col1">
                        {casesList1}
                    </div>
                    <div className="second-page-col2">

                        {casesList2}
                    </div>
                    <div className="second-page-col2">
                        {casesList3}
                    </div>
                </div>
                <div className="interval-150" />
            </div>
        );
    }
}

//网页主模块
class Case extends React.Component {
    //跳转传参
    handleClick(address, myid) {
        this.props.history.push(address + "/" + myid);
    }

    //改变产品筛选条件
    productChange(choose) {
        this.setState({
            casetype: "产品",
            productName: choose,
        })
    }

    //改变业务筛选条件
    businessChange(choose) {
        this.setState({
            casetype: "业务领域",
            businessName: choose,
        })
    }

    //----从此处开始
    constructor(props) {
        super(props);
        this.state = {
            casetype: "",
            mytype: "", //传来的2级子类    
            productName: "", //产品名
            businessName: "", //业务名   
        }
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        if (typeof (this.props.match.params) !== "undefined") {
            this.setState({ casetype: this.props.match.params.mycase })
            this.setState({ productName: this.props.match.params.mytype })
            this.setState({ businessName: this.props.match.params.mytype })
        }
    }
    //获取相关id的高度
    getHight(id) {
        var a = $("#" + id).offset().top;
        var b = $(window).scrollTop();
        var c = $(window).height();
        return (a - b - c);
    }
    //在这里绑定滚动播放动画事件
    showAnimation() {
        let secondHeight = this.getHight("case-second");
        if (secondHeight < 200) {
            $("#case-second").addClass("moveup")
        }
    }
    //-----到这里结束
    render() {

        return (
            <div>
                <Head />
                <h1 style={{ textIndent: '2.77777778vw',fontSize:'2.5vw' }}>应用案例</h1>
                <div className="case">
                    <FirstPage />
                    <SecondPage casetype={this.state.casetype} productName={this.state.productName}
                        businessName={this.state.businessName} productChange={(choose) => this.productChange.bind(this, choose)}
                        businessChange={(choose) => this.businessChange.bind(this, choose)} mytype={this.state.mytype}
                        handleClick={(myid) => this.handleClick.bind(this, "/caseDetails", myid)} />
                </div>
                <Bottom />
            </div >
        );
    }
}

export default Case;