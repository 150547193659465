import '../../../global.js';
// global.config.url
const gcurl = global.config.url;

const baseUrl = `${gcurl}/newgrand/picture/img/new-product/mi6/`;
export default {
  // 简介
  sessionA: {
    imgSrc: `${gcurl}/newgrand/picture/img/%E4%BA%A7%E5%93%81/mi6/banner.png`,
    describe:
      '新中大mi6云企业管理平台，帮助企业快速建立由云平台支持的企业级专业知识库平台，提升工作人员整体水平。平台提供丰富的专业化功能、多元化的方法、个性化的信息访问和报告，以及行业指南，快速搭建团队的协同工作平台，建立顺畅的沟通渠道。mi6云企业管理平台采用可扩展的技术架构，有助于实现企业综合项目管理、财务管理、供应链管理、合同管理、发票管理、企业资金管理和费控的灵活管理。',
    background: 'linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)',
    title: 'mi6云企业管理平台',
    imgStyle: {
      maxHeight: '100%'
    },
    subtitle: {
      text: '成长型企业智能化管理平台',
      outterStyle: {
        border: '2px solid #BBB',
        padding: 6,
        borderRadius: 3,
        width: 'fit-content',
        marginBottom: '1.66vw'
      },
      innerStyle: { fontSize: '1.8vw' }
    }
  },

  sessionB: {
    src: gcurl + '/NewGrand/media/mi6-20231008.mp4'
  },

  // 产品特点
  sessionC: {
    colCount: 6,
    rowCount: 1,
    default: {
      backgroundImage: (x) => `url(${baseUrl}icon/特点图标--${x}.png)`
    },
    items: ['B/S架构网页登录', '免配置直接应用', '允许企业自定义', '内置管理体系', '经济实惠', '多端入口'].map(
      (e, i) => ({ iconPosition: { x: i, y: 0 }, describe: e })
    ),
    textPanel: {
      col: 4,
      row: 2,
      items: [
        '内置行业标准化业务管理流程，对标优秀企业管控。',
        '允许企业自定义基础数据、自定义单据界面、自定义审批流。',
        '内置大量行业客户基础数据、单据打印格式、管理分析报表、管理分析看板、管理分析预警。',
        '企业只需维护组织架构、员工、往来单位及权限分配。',
        '公有云部署按年付费用，随时启用和停用，方便灵活。',
        '公有云产品免升级费，自动升级，免服务器，无接触式应用,免备份，数据安全可靠。',
        '允许企业自定义单据，自定义报表。',
        'PC端、手机端等多入口应用，随时随地轻松管理。'
      ]
    }
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}产品模块图.png`
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: (x) => `url(${baseUrl}modelTitle/系统模块标题--${x}.png)`
    },
    items: [
      '有效的帮助企业实现财务管理的自动化，提高财务工作的效率和质量，实现对财务的集中管理、资源共享，自动统计、远程管理，降低总体运营成本，强化财务管控力度，为经营决策提供支持，实现业务与财务原生一体化，加强风险控制能力。内置财务凭证模板，多集团管理。',
      '帮您提供供应商寻源，准入，比价决策系统化管理供应商，提供多维度的销售价格政策，提升企业销售利润率，精准的库存管理，全程移动仓库作业， 智能化仓库管理。',
      '建立从项目立项到项目验收一站式项目业务管理平台，打通各部门间壁垒，使信息快速传递，确保各部门之间有效的沟通，及时掌握项目的各项进展，合理的分配资源，最终实现项目最终目标。',
      '将合同中的关键要素紧密嵌入企业的业务与管理流程，并结合着预警与审批机制， 有效进行合同的全生命周期管理。使企业各部门围绕与合同内容而开展的相关工作都可以通过系统实现智能化协同，从而最大限度提升了管理效率。',
      '以预算为主线的集团费控平台，优化审批流程，提升效率，确保合规性，降低风险， 实现成本控制。包含借款报销平台智能报销、费用分析表、个人往来统计表、个人往来明细表等。',
      '随着推进“互联网+”与纸质票据电子化的政策推动，电子票已逐渐成为会计凭证和交易结算的主流形式，新中大金税集成发票风险管控平台由此应运而生，以实现发票管理 智能化、发票风险可控化、税金计算自动化的目标。',
      '以企业资金为核心，提升企业资金精细化管理，实现企业资金计划编制，资金收支的实时监控和事后分析，降低资金成本，提高资金使用率。',
      '通过全过程商机推进管控以及全过程投标管理协助企业人员缩减销售周期和销售成本、增加收入、寻找扩展业务所需的新的市场和渠道以及提高客户的价值、满意度、赢利性和忠实度。',
      '通过员工档案、入转调离、劳动合同、考勤管理、薪资管理等有效降低工作的重复性和复杂性，提高企业整体科技含量和管理效率，从而来实现员工信息的全面化、劳动合同的模板化、入转调离一体化、职位岗位的体系化、薪资核算自动化的整体目标。',
      '真正的实现企业行政线上无纸化办公，有效的节省工作成本、促进信息共享、提高行政办公能力、提高工作效率、打造优秀的团队意识、构建科学的管理模式。',
      '随时随地进行业务发起，实时了解经营快报，预警消息自动推送到移动终端；通过项目看板及时洞察项目进度节点。'
    ].map((e) => ({ describe: e }))
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history: '/caseDetails/1033'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history: '/caseDetails/1051'
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history: '/caseDetails/1052'
      }
    ]
  }
};
