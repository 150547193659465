import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/19/`;
export default {
  sessionA: {
    imgSrc: `img/product_hunningtu_banner_pic01.png`,
    describe: "新中大建筑建材企业信息化管理平台是面向建筑建材行业集团级管控软件，产品汇聚企业生产、管理、销售 和财务管控于一身，以企业项目开工为入口，以销售管理为主线，以财务管理为核心。运用现代企业管理理念、 项目管理思想和信息技术，帮助企业建设覆盖建筑建材企业全集团业务机构，安全可靠的满足业务需求的信息化 平台。产品围绕企业财务管理、生产和销售管控三个层面，推进企业市场经营、生产管控、风险管控、内部流 转、票据管控的全过程数字化、网络化、集成化。 同时，通过建立企业内部风控平台，解决建材企业生产过程不受控、企业产能无法准确反应、管理过程无序 等问题，实现信息处理数字化、信息组织集成化，信息传递网络化，业务管理流程化。 作为中国专业从事建筑建材行业管理研究的大型管理软件供应商之一，新中大在建筑建材行业领域中形成了 以“人、机、料、法、环”为核心管理点的智能制造管理体系，提供涵盖建筑建材相关行业的产品和解决方案，并 重点服务于混凝土生产制造企业、建筑建材贸易企业、PC构件生产企业等。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML: '混凝土生产制造企业方案介绍',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  // 混凝土生产企业数字化8大现状
  sessionB: {
    imgSrc: `img/project_pic01.jpg`,
  },
  sessionC: {
    imgSrc: `img/project_pic02.jpg`
  },
  sessionD: {
    imgSrc: `img/project_pic03.jpg`
  },
  sessionE: {
    title: '上海建工建材科技集团股份有限公司',
    describe: `上海建工建材科技集团股份有限公司成立于1953年，是世界500强上海建工集团股份有限公司的全资子公司，是上海地区预拌混凝土、预制构件生产销售的龙头企业，业务范围辐射江苏、浙江、安徽、江西、四川等省份的多个重点城市，配套有建材石矿、外加剂、泵车运输等产业链。建材科技集团预拌混凝土年产量超5600万立方米，位居上海第一、全国第三、世界第五，具有超高泵送混凝土、特种混凝土等各类高性能功能化混凝土的研发和生产能力。拥有丰富的预制构件生产经验和强大的预制构件生产能力，年产能近75万方。拥有完善的科技研发三级体系，技术和产品广泛应用于超高层建筑、地铁隧道、市政建设、公共建筑、工业建筑和民用建筑等领域，拥有全国建筑业科技进步与技术创新先进企业证书和上海科委颁发的“高新技术企业”证书，一批小众精品混凝土引领行业技术新风尚。`,
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}